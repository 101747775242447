import { useState, useEffect, createContext, useContext, useRef } from 'react'
import { ethers, Wallet } from 'ethers';
//localForage
import localforage from 'localforage';

export const EthereumContext = createContext()

export function EthereumProvider({ children }) {

	const [account,setAccount]			= useState();
	const [signer,setSigner] 			= useState();
	const [hstat, setHstat]				= useState();
	

	const accountRef=useRef();
	const signerRef=useRef();
	const hstatRef = useRef();	


//------------------------------------------------------------------------	
 useEffect(() => {	
	localforage.getItem('account').then(data2 => {
		if (typeof data2 === 'string' && data2.length >0) {
			accountRef.current = data2;
		}
		localforage.getItem('signer').then(data3 => {
			if (data3 !== null) {
				signerRef.current = data3;
			};
			localforage.getItem('hstat').then(data4 => {
				console.log ('data2: ',data2);
				console.log ('data3: ',data2);
				console.log ('data4: ',data4);
				if (data4 !== null) {
					hstatRef.current = data4
				} else { hstatRef.current = false};
			})
			.catch(error => { console.log('Error getItem hstat:', error);});
		})
		.catch(error => { console.log('Error getItem signer:', error);});		
	})
	.catch(error => { console.log('Error getItem account:', error);});
  }, []);

/*  
	setAccount(accountRef.current);
	setSigner(signerRef.current);
	setHstat(hstatRef.current);
*/	
	const [networkId,setNetworkId]		= useState(66265);
	const [encSignerl,setEncSignerl]	= useState();
	const [statInfo, setStatInfo]		= useState();	
	const [challengeJson,setChallengeJson]=useState("");
  
  return (
<EthereumContext.Provider
  value=
  {{ hstatRef, hstat, setHstat, accountRef, account, setAccount, signerRef, signer, setSigner, statInfo, setStatInfo,challengeJson,setChallengeJson }} 
>
  {children}
</EthereumContext.Provider>
  )
}

export function useEthereumContext() {
  return useContext(EthereumContext)
}
